//@ts-ignore
import { StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
  section: {
    padding: "5% 12%",
    textAlign: "center",
    "@media (min-width: 568px)": {
      padding: "5% 12%",
      textAlign: "center",
    },
    "@media (min-width: 768px)": {
      padding: "5% 8%",
      textAlign: "center",
    },
    "@media (min-width: 1024px)": {
      padding: "5%",
      textAlign: "center",
    },
  },
  title: {
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
    justifyContent: "center",
  },
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important",
    minWidth: 0,
    minHeight: 0,
  },
  borderStyle: {
    border: 0,
    height: 5,
    margin: "0 auto 5% auto",
    width: 50,
    backgroundImage: "linear-gradient(to right,#ff8a00,#da1b60)",
    borderRadius: 5,
  },
  titleStyle: {
    textAlign: "center",
  },
  container: {
    width: "100%",
  },
});

export default styles;
