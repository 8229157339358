//@ts-nocheck
import React from "react";
import {
  Container,
  ContainerCard,
  Header,
  ScrollFlow,
  Wallpaper,
  TokenContainer,
} from "./components";
import headerConfig from "./config/header.config.json";
import cardContentConfig from "./config/cardContent.config.json";
import { useTokenDisplay } from "./hooks/useTokenDisplay";
// let renderCount = 0;
export const TokenDisplay = ({ cardData, bandLogo }: props) => {
  const { message, logo, id } = useTokenDisplay();

  // console.log(`TokenDisplay rerender`);
  // renderCount += 1;
  // console.log(`${TokenDisplay.name}. renderCount: `, renderCount);
  return (
    <Container>
      <>
        <Wallpaper
          imgURL={
            "https://s3.ca-central-1.amazonaws.com/io.vlinder.tribe/band_logos/Tribe_4.jpeg" ??
            ""
          }
        />
        {id ? (
          <>
            <ScrollFlow>
              <ContainerCard>
                <>
                  <Header headerContent={headerConfig} />
                  <div style={{ height: "80vh" }}>
                    <TokenContainer
                      containerContent={cardContentConfig}
                      bandLogo={logo ?? ""}
                      cardData={message?.cardData ?? []}
                    />
                  </div>
                </>
              </ContainerCard>
            </ScrollFlow>
            <div
              style={{
                position: "absolute",
                bottom: "10px",
                left: "50%",
                width: "100px",
                height: "40px",
                marginLeft: "-50px",
                backgroundColor: "rgba(0,0,0,0.8)",
              }}
            >
              <p
                style={{ color: "rgba(255,255,255,0.4)", textAlign: "center" }}
              >
                {message?.fingerprint ?? "999"}
              </p>
            </div>
          </>
        ) : (
          <div
            style={{
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <p style={{ fontWeight: "bold", fontSize: "30px" }}>
              Oops! Looks like you followed a bad link !
            </p>
          </div>
        )}
      </>
    </Container>
  );
};
